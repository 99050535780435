<template>
  <div class="coupons-list">
    <breadcrumb :breadcrumbList="breadcrumbList"></breadcrumb>
    <div class="main-content">
      <div class="top-content">
        <div class="left">
          <el-button class="add-staff" type="primary" @click="handleAddClick">绑定商品服务</el-button>
          <el-button class="add-staff" type="warning" :disabled="!multipleSelection.length" @click="handleAllRemoveClick">批量解绑</el-button>
        </div>
        <div class="right">
          <div>
            <el-input @clear="handleClear" clearable v-model="ruleForm.tripartiteServeName" class="search" prefix-icon="search"
              placeholder="美团点评商品" />
          </div>
          <div>
            <el-input @clear="handleClear" clearable v-model="ruleForm.serveName" class="search" prefix-icon="search"
              placeholder="指挥官商品" />
          </div>
          <div>
            <el-date-picker
              @change="handleDateChange"
              v-model="ruleForm.date"
              type="daterange"
              range-separator="到"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              value-format="YYYY-MM-DD"
            />
          </div>
          <div class="search-wrap">
            <el-button class="add-staff" type="primary" @click="handleSearchClick">筛选</el-button>
          </div>
        </div>
      </div>
      <div style="margin-top: 20px;">
        <el-table :data="dataList" border @selection-change="handleSelectionChange">
          <el-table-column align="center" type="selection" width="55" />
          <el-table-column align="center" type="index" label="序号" width="80" />
          <el-table-column align="center" prop="tripartiteServeName" label="美团点评商品" />
          <el-table-column align="center" prop="serveName" label="指挥官商品"/>
          <el-table-column align="center" prop="createTime" label="绑定时间"/>
          <el-table-column align="center" prop="createUser" label="绑定操作人" />
          <el-table-column align="center" label="操作">
            <template #default="scope">
              <el-button type="text" size="small" @click="handleRemoveClick(scope.row.id)">解绑</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="el-pagination" style="margin-top: 20px;">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="queryInfo.current" :page-size="queryInfo.size" :page-sizes="[10, 20, 30]"
              layout="total, sizes, prev, pager, next, jumper" :total="dataTotal"></el-pagination>
          </div>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import Breadcrumb from '@/components/breadcrumb/breadcrumb.vue'
import { breadcrumbList } from './config'
import { getServeBindList,unbindServe,bathUnbind } from "@/service/main/serve";
import { useRouter } from 'vue-router'
import { popup } from '@/utils/pop-up'
import { ElMessage } from 'element-plus'

export default defineComponent({
  props: {

  },
  components: {
    Breadcrumb,
  },
  setup() {
    const dataList = ref([])
    const router = useRouter()
    const multipleSelection = ref([])
    const dataTotal = ref(0)
    const queryInfo = reactive({
      current:1,
      size:10,
      type:1
    })

    const handleCurrentChange = (currentPage) => {
      queryInfo.current=currentPage
      initPage()
    }

    const handleSizeChange = (pageSize) => {
      queryInfo.size=pageSize
      initPage()
    }
    const handleDateChange = value => {
      if (value) {
        ruleForm.startDate = value[0]
        ruleForm.endDate = value[1]
      }else{
        ruleForm.startDate = undefined
        ruleForm.endDate = undefined
      }
    }
    const handleSearchClick = () =>{
      queryInfo.tripartiteServeName=ruleForm.tripartiteServeName||undefined
      queryInfo.serveName=ruleForm.serveName||undefined
      queryInfo.startDate=ruleForm.startDate||undefined
      queryInfo.endDate=ruleForm.endDate||undefined
      initPage()
    }
    const handleAddClick = () =>{
      router.push({
        path: "/main/maketing/serveBind"
      })
    }
    const ruleForm = reactive({
      tripartiteServeName:'',
      serveName:'',
      startDate:'',
      endDate:''
    })
    const handleRemoveClick = (id) => {
      popup('确认解绑该商品吗?', async() => {
        let res = await unbindServe(id)
        if (res.code==0) {
          ElMessage.success({
            message: '解绑成功!',
            type: 'success'
          })
          initPage()
        }
      })
    }
    const handleAllRemoveClick = () =>{
      popup('确认解绑选中商品吗?', async() => {
        let list = multipleSelection.value.map(item=>{
          return item.id
        })
        let res = await bathUnbind({ids:list})
        if (res.code==0) {
          ElMessage.success({
            message: '解绑成功!',
            type: 'success'
          })
          initPage()
        }
      })
    }
    const handleSelectionChange = (val) => {
      multipleSelection.value = val
    }
    const initPage = async () => {
      const res = await getServeBindList({ ...queryInfo })
      dataList.value = res.data.list
      dataTotal.value = Number(res.data.total)
    }
    initPage()



    return {
      breadcrumbList,
      dataList,
      queryInfo,
      dataTotal,
      ruleForm,
      handleCurrentChange,
      handleSizeChange,
      handleSearchClick,
      handleSelectionChange,
      multipleSelection,
      handleDateChange,
      handleAddClick,
      handleRemoveClick,
      handleAllRemoveClick
    }

  }
})
</script>

<style scoped lang="less">
.coupons-list {
  min-width: 1080px;
  .top-content {
    display: flex;
    justify-content: space-between;

    &:deep(.el-select) {
      margin-right: 10px;
    }

    .left {}

    .right {
      display: flex;
      div{
        margin-right: 10px;
      }
      .search-wrap {
        margin-left: 10px;
      }
    }

  }
  .main-content {
    padding: 20px;
    margin: 20px;
    background-color: #fff;
    &:deep(.el-table) {
      th.el-table__cell{
        background-color: rgba(239, 239, 239, 1) !important;
        .cell {
          color: rgba(80, 80, 80, 1) !important;
          font-size: 14px !important;
          font-weight: 500 !important;
        }
      }
      .el-table__header-wrapper {
      
        .el-table__cell {

          background-color: rgba(239, 239, 239, 1);
          // padding: 9px 0 !important;
          // border:1px solid rgb(223, 223, 223);

          .cell {
            color: rgba(80, 80, 80, 1) !important;
            font-size: 14px !important;
            font-weight: 500 !important;
          }
        }

      }
    }
    
  }
}
</style>




